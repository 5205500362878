<!--
 * @Editors: wen jing
 -->
<template>
  <div>
    <Header title="qianfanyun"></Header>
    <BannerTitle title="qianfanyun"></BannerTitle>
    <div class="qfyBox">
      <div class="introduce wow slideInUp">
        <p>
          {{ $t("qfy.proArticle1") }}
        </p>
        <p>
          {{ $t("qfy.proArticle2") }}
        </p>
      </div>
      <!-- 服务平台 -->
      <div class="platformBox">
        <div class="title wow slideInUp">{{ $t("qfy.spTit") }}</div>
        <div class="platform">
          <img
            class="wow slideInUp"
            src="@/assets/images/qianfanyun1.png"
            alt=""
          />
          <ul class="wow slideInUp">
            <li>
              <b>{{ $t("qfy.spDesc.num1") }}</b>
              <span>{{ $t("qfy.spDesc.tips1") }}</span>
            </li>
            <li>
              <b>{{ $t("qfy.spDesc.num2") }}</b>
              <span>{{ $t("qfy.spDesc.tips2") }}</span>
            </li>
            <li>
              <b>{{ $t("qfy.spDesc.num3") }}</b>
              <span>{{ $t("qfy.spDesc.tips3") }}</span>
            </li>
            <li>
              <b>{{ $t("qfy.spDesc.num4") }}</b>
              <span>{{ $t("qfy.spDesc.tips4") }}</span>
            </li>
          </ul>
        </div>
      </div>
      <!-- app内容展示 -->
      <div class="cont">
        <div class="title wow slideInUp">{{ $t("qfy.appProTit") }}</div>
        <img
          src="@/assets/images/qianfanyun2.png"
          alt=""
          class="wow slideInUp"
        />
      </div>
      <!-- 流量 -->
      <div class="flow">
        <div class="title wow slideInUp">{{ $t("qfy.trEnTit") }}</div>
        <ul class="wow slideInUp">
          <li>
            <img src="@/assets/images/qianfanyun3.png" alt="" />
            <b>{{ $t("qfy.trEn.tit1") }}</b>
            <span>{{ $t("qfy.trEn.tips1") }}</span>
          </li>
          <li>
            <img src="@/assets/images/qianfanyun4.png" alt="" />
            <b>{{ $t("qfy.trEn.tit2") }}</b>
            <span>{{ $t("qfy.trEn.tips2") }}</span>
          </li>
          <li>
            <img src="@/assets/images/qianfanyun5.png" alt="" />
            <b>{{ $t("qfy.trEn.tit3") }}</b>
            <span>{{ $t("qfy.trEn.tips3") }}</span>
          </li>
          <li>
            <img src="@/assets/images/qianfanyun6.png" alt="" />
            <b>{{ $t("qfy.trEn.tit4") }}</b>
            <span>{{ $t("qfy.trEn.tips4") }}</span>
          </li>
        </ul>
      </div>
      <!-- 合作案例 -->
      <div class="caseBox">
        <div class="title wow slideInUp">{{ $t("qfy.cooperationTit") }}</div>
        <ul class="wow slideInUp">
          <li>
            <p>{{ $t("qfy.cTit1") }}</p>
            <img src="@/assets/images/qianfanyun7.png" alt="" />
          </li>
          <li>
            <p>{{ $t("qfy.cTit2") }}</p>
            <img src="@/assets/images/qianfanyun8.png" alt="" />
          </li>
          <li>
            <p>{{ $t("qfy.cTit3") }}</p>
            <img src="@/assets/images/qianfanyun9.png" alt="" />
          </li>
        </ul>
      </div>
    </div>
    <Hotline></Hotline>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../../components/header.vue";
import BannerTitle from "../../components/bannerTitle.vue";
import Footer from "../../components/footer.vue";
import Hotline from "../../components/hotline.vue";
export default {
  name: "zhijuyun",
  components: {
    Header,
    BannerTitle,
    Footer,
    Hotline
  }
};
</script>

<style lang="scss">
.qfyBox {
  width: 1300px;
  margin: auto;
  .introduce {
    width: 1000px;
    margin: auto;
    font-size: 16px;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
    line-height: 30px;
    text-align: center;
  }
  .title {
    font-weight: 600;
    color: rgba(34, 34, 34, 1);
    line-height: 56px;
    font-size: 40px;
    text-align: center;
  }
  .platformBox {
    margin-top: 99px;
    height: 717px;
    .platform {
      margin-top: 57px;
      img {
        width: 647px;
        float: left;
      }
      ul {
        width: 600px;
        float: right;
        padding-top: 127px;
        li {
          width: 210px;
          float: left;
          text-align: center;
          margin-left: 66px;
          margin-bottom: 66px;
          b {
            display: block;
            color: rgba(5, 100, 205, 1);
            line-height: 70px;
            font-size: 30px;
            font-weight: 600;
          }
          span {
            font-weight: 400;
            color: rgba(153, 153, 153, 1);
            line-height: 22px;
          }
        }
      }
    }
  }
  .cont {
    margin-bottom: 84px;
    img {
      width: 900px;
      height: 510px;
      display: block;
      margin: 40px auto 0;
    }
  }
  .flow {
    height: 433px;
    margin: auto;
    ul {
      margin-top: 91px;
      li {
        width: 128px;
        margin: 0 98px;
        float: left;
        text-align: center;
        img {
          width: 66px;
          margin: auto;
          display: block;
        }
        b {
          display: block;
          font-size: 30px;
          // font-weight: 600;
          font-weight: normal;
          color: #0564CD;
          line-height: 42px;
          margin: 17px 0 7px;
        }
        span {
          display: block;
          font-weight: 400;
          color: rgba(153, 153, 153, 1);
          line-height: 22px;
        }
      }
    }
  }
  .caseBox {
    height: 636px;
    ul {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      li {
        width: 360px;
        p {
          text-align: center;
          line-height: 28px;
          font-size: 20px;
          color: rgba(5, 100, 205, 1);
          font-weight: 600;
          margin-bottom: 28px;
        }
        img {
          width: 100%;
        }
      }
    }
  }
}
</style>
